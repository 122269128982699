import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './components/Layout';
import AppRoutes from './routes';
import { ConfigProvider } from 'antd';
import 'video-react/dist/video-react.css';
import './assets/fonts/fonts.scss'
import './index.css';
import './App.css';
import './styles.scss'
import { StyleProvider } from '@ant-design/cssinjs';
import { LoginProvider } from './context/LoginContext';

function App() {
  const host = window.location.host;
  const hostname = window.location.hostname;
  const targetAdminHost = hostname !== 'localhost' ? 'https://console.' + host : 'http://' + host
  return (
    <div className="App">
      <StyleProvider hashPriority="high">
        <ConfigProvider
          theme={
            {
              token: {
                colorPrimary: '#722ED1',
              },
              components: {
                Button: {
                  borderRadius: 24,
                  colorPrimary: '#722ED1',
                  defaultColor: '#722ED1',
                  defaultBorderColor: '#722ED1',
                  defaultActiveBg: '#722ED1',
                  defaultActiveBorderColor: '#722ED1',
                  defaultActiveColor: '#fff',
                  primaryShadow: 'none',
                  colorPrimaryBg: '#722ED1',
                },
                Select: {
                  optionSelectedBg: '#722ED1',
                },
                Table: {
                  headerBg: '#EFDBFF',
                  headerColor: '#000',
                  cellFontSize: 16,
                  headerBorderRadius: 0,
                }
              }
            }
          }
        >
          <Router basename='/' >
            <LoginProvider>
              <Layout>
                <AppRoutes />
                <iframe id="targetFrame" src={`${targetAdminHost}/receiver`} title="Target Frame" style={{ display: 'none' }} />
              </Layout>
            </LoginProvider>
          </Router>
        </ConfigProvider>
      </StyleProvider>
    </div>
  );
}

export default App;
