import './styles/info.scss'
import { Link } from 'react-router-dom'
const Info = () => {
    return (
        <div className="info">
            <img src={require('../assets/images/home/info.png')} alt="" />
            <p>Please Login With Your Computer To Use Full Functionality</p>
            <p className='info_text'>Using your computer to log in to AIGIC, not only can you use the complete functions of all models, copy model codes to your application with just one click, but you can also receive free Computility every day.</p>
            <Link to="/playground"><button className="exploreBtn">Explore Other</button></Link>
        </div>
    )
}

export default Info;