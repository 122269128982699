
import { Row, Col, Button, Tabs, Input, Flex, Spin, message } from 'antd'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Fetch_ from "../utils/fetch";
import { useLoginContext } from '../context/LoginContext';

import './styles/playground_list.scss'

const PlaygroundList = () => {
    const { isLogin, login } = useLoginContext();

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    async function handleCollect(event, type, id) {
        event.stopPropagation()
        if (!isLogin) {
            login((res, error) => {
                if (error == null) handleCollect()
            })
            return
        }
        type = type === 0 ? 'add' : 'cancel'
        try {
            const result = await Fetch_(`/admin/api/favorite/${type}`, {
                method: "post",
                body: JSON.stringify({ task_type_id: Number(id) }),
            })
            const res = await result.json();
            if (res.code === 200) {
                message.success(type === 'add' ? 'Collection successful' : 'Cancel collection successful')
                fetchData('collect')
            }
        } catch (error) {
            console.log(error)
        }
    }

    async function getData(params, eventIn) {
        try {
            if(!eventIn) setLoading(true)
            const res = await Fetch_('/admin/api/task/models', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...params, size: 100 }),
            });
            const jsonData = await res.json()
            return jsonData
        } catch (error) {
            setLoading(false)
            console.log(error)
        }

    }

    async function getCategorys(params) {
        const res = await fetch('/admin/api/task/allcategorys', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });

        const jsonData = await res.json()
        return jsonData
    }

    const [dataArr, setDataArr] = useState([])
    const [activeTab, setActiveTab] = useState(0);
    const [categorys, setCategorys] = useState([])
    const [keyword, setKeyword] = useState('')

    const handleTabClick = async (tab) => {
        console.log("handleTabClick", tab)
        tab = parseInt(tab)
        setActiveTab(tab);
        const data = await getData({ keyword: keyword, category: tab });
        setDataArr(data.data.data)
        setLoading(false)
    };

    const handleSearch = async () => {
        const data = await getData({ keyword });
        setDataArr(data.data.data)
        setLoading(false)
    }

    const goPlayground = (item) => {
        navigate(`/info`)
    }

    async function fetchData(eventIn) {

        const data = await getData({}, eventIn)
        if (data && data.data && data.data.data) {
            setDataArr(data.data.data)
            setLoading(false)
            const categoryData = await getCategorys()
            if (categoryData && categoryData.data && categoryData.data.length) {
                let categorys = categoryData.data.map(item => {
                    item.key = item.id + ""
                    item.label = item.en_desc
                    return item
                })
                categorys.unshift({
                    key: "0",
                    label: "All",
                    id: 0,
                    desc: "All",
                    en_desc: "All",
                    sort: 0,
                })
                setCategorys(categorys)
            }
        }

    }

    useEffect(() => {
        fetchData()
    }, [])
    return (
        <div className="playground_list">
            <div className="list_content">
                <div className="search">
                    <Input allowClear onChange={e => setKeyword(e.target.value)} placeholder="Search" prefix={<img style={{ height: '4.1vw', width: '4.1vw' }} src={require('../assets/images/playground/search.png')} />} />
                    <button onClick={()=> handleSearch()}>Search</button>
                    {/* <Button icon={<img src={require('../assets/images/playground/collect_filter.png')} />}> default collation</Button> */}
                </div>

                <div className="list">
                    {loading ? <Spin size='large' spinning={loading} />
                        : !dataArr || dataArr.length <= 0 && !loading ? (
                            <div className="no_data">
                                <img src={require('../assets/images/playground/no_data.png')} alt="" />
                                <span>You haven't collected the model yet</span>
                            </div>
                        ) : (
                            <Flex wrap="wrap" className="list_items">
                                {
                                    dataArr && dataArr.length > 0 && dataArr.map((item, index) => {
                                        return (
                                            <div key={index} className="item" onClick={() => goPlayground(item)}>
                                                <div className="item_top">
                                                    {/* 2 4 7 11 text */}
                                                    <div className="tags">
                                                        {
                                                            item.type === 1 || item.type === 6 || item.type === 8 ?
                                                                <img className='typeIcon' src={require('../assets/images/playground/type_img.png')} alt="" />
                                                                : item.type === 2 || item.type === 4 || item.type === 7 || item.type === 11 ?
                                                                    <img className='typeIcon' src={require('../assets/images/playground/type_chat.png')} alt="" />
                                                                    : item.type === 3 || item.type === 5 || item.type === 9 ?
                                                                        <img className='typeIcon' src={require('../assets/images/playground/type_mv.png')} alt="" />
                                                                        : item.type === 10 ?
                                                                            <img className='typeIcon' src={require('../assets/images/playground/type_video.png')} alt="" />
                                                                            : <img className='typeIcon' src={require('../assets/images/playground/type_img.png')} alt="" />
                                                        }
                                                        {item.tags && item.tags.map((tag, index) => {
                                                            return (
                                                                <span key={index} className="tag">
                                                                    {tag}
                                                                </span>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="item_con">
                                                    <span className='name'>{item.tit}</span>
                                                    <span className='desc'>{item.content}</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </Flex>
                        )}


                </div>


            </div>
        </div>
    )
}

export default PlaygroundList