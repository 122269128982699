import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children }) => {
  return (
    <div className='relative flex min-h-screen flex-col overflow-hidden text-zinc-100'>
      <Header></Header>
      <div className="flex flex-1 flex-col content">
        {children}
      </div>
      {/* <Footer></Footer> */}
    </div>
  );
};

export default Layout;
