import React, { useState, useEffect } from "react";
import { Button, Input, message } from "antd";
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import Stable_Diffusion_XL from '../assets/images/home/model_icon1.png'
import LLAMA from '../assets/images/home/model_icon2.png'
import Musicgen from '../assets/images/home/model_icon3.png'
import StyleTTS_2 from '../assets/images/home/model_icon4.png'
import LoadingGif from '../assets/images/playground/loading.gif'

// import './styles/home.scss'
import './styles/home_dark.scss'

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { useLoginContext } from '../context/LoginContext';
import Fetch_ from "../utils/fetch";

const Home = () => {
    const [activeModel, setActiveModel] = useState({})
    const [activeModelIndex, setActiveModelIndex] = useState(1)
    const [play, setPlay] = useState(false)

    const [activeModelImg, setActiveModelImg] = useState(Stable_Diffusion_XL)
    const [activeModelType, setActiveModelType] = useState(1)

    const [models, setModels] = useState([])
    const { isLogin, setIsLogin } = useLoginContext();
    const [currentApiKey, setCurrentApiKey] = useState('');
    const [loading, setLoading] = useState(false);
    const [resCon, setResCon] = useState('');
    const [resTask, setResTask] = useState({});
    const [prompt, setPrompt] = useState('')
    const [countDown, setCountDown] = useState(60);
    const [remainingHours, setRemainingHours] = useState(0);
    const [remainingMinutes, setRemainingMinutes] = useState(0);
    const [remainingSeconds, setRemainingSeconds] = useState(0);

    useEffect(() => {
        switch (activeModelIndex) {
            case 1:
                setActiveModelImg(Stable_Diffusion_XL)
                setActiveModelType(1)
                break;
            case 2:
                setActiveModelImg(LLAMA)
                setActiveModelType(2)
                break;
            case 3:
                setActiveModelImg(Musicgen)
                setActiveModelType(3)
                break;
            case 4:
                setActiveModelImg(StyleTTS_2)
                setActiveModelType(4)
                break;

            default:
                break;
        }
        setResTask(null)
    }, [activeModelIndex])

    useEffect(() => {
        if (isLogin) {
            queryApiKeys()
        } else {
            setCurrentApiKey('')
        }
    }, [isLogin]);

    useEffect(() => {
        fetchData()
    }, []);

    const fetchData = async () => {
        const token = localStorage.getItem("token")
        if (token) {
            setIsLogin(true)
            await queryApiKeys()
        }
        try {
            let images = [Stable_Diffusion_XL, LLAMA, Musicgen, StyleTTS_2]
            let models = await getModels()
            if (models && models.length) {
                images.map((item, index) => {
                    if (index < models.length) {
                        let model = models[index]
                        model.image = item
                    }
                })
            }
            setModels(models)
            initFirstSelectModel(models)
        } catch (error) {
            console.log(error)
        }
    }


    const getModels = async () => {
        const res = await fetch('/admin/api/task/examples', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                page: 1,
                size: 4
            }),
        });

        const jsonData = await res.json()
        if (jsonData.code === 200 && jsonData.data && jsonData.data.data && jsonData.data.data.length) {
            return jsonData.data.data
        }
        return []
    }

    const initFirstSelectModel = async (models) => {
        if (models && models.length) {
            let model = models[0]
            if (model.examples && model.examples.length) {
                let example = model.examples[0]
                if (example.input && example.input.length) {
                    let input = JSON.parse(example.input)
                    setPrompt(input.prompt)
                }
            }
            setActiveModel(model)
        }
    }

    const switchModel = async (data, index) => {
        if (data.examples && data.examples.length) {
            let example = data.examples[0]
            if (example.input && example.input.length) {
                let input = JSON.parse(example.input)
                setPrompt(input.prompt)
                return input.prompt
            }
        }
        setActiveModelIndex(index + 1)
        setActiveModel(data)
    }

    async function queryApiKeys() {
        try {
            const result = await Fetch_("/admin/api/app/apikeysfront", {
                method: "GET",
            })
            const res = await result.json();
            if (res.code === 200) {
                let data = res.data.data
                if (data && data.length > 0) {
                    setCurrentApiKey(data[0].api_key)
                }
            } else if (res.code === 401) {
                // message.error(res.msg)
                setIsLogin(false)
            }
        } catch (error) {
            console.log(error)
            // setIsLogin(false)
        }
    }

    const queryApi = async (data) => {
        let timing = null
        try {
            if (activeModel.estimat_exe_time && activeModel.estimat_exe_time > 0) {
                let count = Number(activeModel.estimat_exe_time) + Number(activeModel.start_up_time)
                setCountDown(count)
                timing = setInterval(() => {
                    const hours = Math.floor(count / 3600);
                    const minutes = Math.floor((count % 3600) / 60);
                    const seconds = count % 60;
                    setRemainingHours(hours);
                    setRemainingMinutes(minutes);
                    setRemainingSeconds(seconds);
                    count -= 1;
                    setCountDown(count)
                    if (count === 0) {
                        clearInterval(timing)
                        setRemainingHours(0);
                        setRemainingMinutes(0);
                        setRemainingSeconds(0);
                    }
                }, 1000)
            }
            const response = await fetch('/api/v1' + activeModel['api_path'], {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'apikey': isLogin ? currentApiKey : '4oipB83LZCpkrVN3i12f38WcBUYH5MR9',
                    'async': false,
                },
                body: JSON.stringify(data),
            });


            if (response.ok) {
                if (response.status === 200) {
                    try {
                        const resultJson = await response.json();
                        const isResultTask = resultJson && resultJson.task
                        if (isResultTask && resultJson.task.is_success === false) {
                            setLoading(false)
                            setResTask({ ...resultJson.task, output: resultJson.output })
                            if (resultJson.task.exec_code == 409) {
                                message.error('Already running a prediction')
                                return
                            }
                            if (resultJson.task.api_error && resultJson.task.api_error.message) {
                                message.error(resultJson.task.api_error.message)
                            } else if (resultJson.task.task_error) {
                                message.error(resultJson.task.task_error)
                            }

                        }
                        if (resultJson && resultJson.task.is_success && resultJson.output) {
                            setResTask(null)
                            setLoading(false)
                            message.success('Success')
                            if (Array.isArray(resultJson.output) && resultJson.output.length > 0) {
                                // const output = [].concat(...resultJson.output)
                                const outputArr = resultJson.output.filter(item => Array.isArray(item)).flat() || []
                                const outputArrStr = resultJson.output.filter(item => typeof (item) === 'string').flat() || []
                                setResCon(outputArrStr.length > 0 ? outputArrStr : outputArr)
                            }
                        }
                    } catch (error) {
                        setResTask(null)
                        if (response.url) {
                            setResCon(response.url)
                            setLoading(false)
                        } else {
                            setLoading(false)
                            message.error(response.statusText)
                        }
                    }
                }
                clearInterval(timing)
                setRemainingHours(0);
                setRemainingMinutes(0);
                setRemainingSeconds(0);
            } else {
                message.error(`Request failed with status ${response.status}`)
                setResTask(null)
                setLoading(false)
                clearInterval(timing)
                setRemainingHours(0);
                setRemainingMinutes(0);
                setRemainingSeconds(0);
            }
        } catch (error) {
            setLoading(false)
            setResTask(null)
            clearInterval(timing)
            setRemainingHours(0);
            setRemainingMinutes(0);
            setRemainingSeconds(0);
            console.error('Error posting data:', error)
        }
    }

    const optSubmit = async (values) => {
        setActiveModel(values)
        if (loading) return
        if (activeModel.publish_status !== 1) {
            message.warning("This model isn't open yet.")
            return
        }
        setResCon('')
        setLoading(true)
        let input = {
            prompt: prompt
        }
        if (values.examples && values.examples.length) {
            let example = values.examples[0]
            if (example.input && example.input.length) {
                input = JSON.parse(example.input)
                input.prompt = prompt
            }
        }
        const postData = {
            input: input
        }
        
        queryApi(postData)
    }

    const models_settings = {
        className: "center variable-width",
        arrows: false,
        centerMode: true,
        infinite: true,
        variableWidth: true,
        // slidesToShow: 1,
        speed: 300
    };

    const settings = {
        className: "slider variable-width",
        arrows: false,
        infinite: true,
        variableWidth: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <div className="home">
            <div className="large_bg">
                <section className="first_page">
                    <div className="banner_text">
                        <p>Run your own AI Application</p>
                        <p>Just One Line Of Code</p>
                    </div>

                    <p className="banner_text_des">You don't need high configuration hardware or complex local deployment.</p>
                    <p className="banner_text_des">On AIGIC, you only need to copy a line of code to create your own AI application.</p>
                    <Link to="/playground"><div className="start_btn">Get Free Computility</div></Link>
                    <img src={require('../assets/images/home/cloud1.png')} alt="" className="cloudIcon cloud1" />
                    <img src={require('../assets/images/home/cloud2.png')} alt="" className="cloudIcon cloud2" />
                    <img src={require('../assets/images/home/cloud3.png')} alt="" className="cloudIcon cloud3" />
                </section>

                <section className="second_page">
                    <div className="slider-container">
                        <Slider {...models_settings}>
                            {
                                models && models.length && models.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            {
                                                index === 0 ? (
                                                    <div className="">
                                                        <div className="top_generate">
                                                            <p className="modelName">{item.tit}</p>
                                                            <div className="playground" style={resCon || loading ? { background: '#270966' } : { backgroundImage: `url(${Stable_Diffusion_XL})` }}>
                                                                {
                                                                    loading ?
                                                                        (<div className="generating">
                                                                            <img src={LoadingGif} alt="" />
                                                                            {countDown > 0 ? <p>ETA: {`${remainingHours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`}</p> : <p>Accelerating generation...</p>}
                                                                        </div>)
                                                                        :
                                                                        !loading && resTask && resTask.is_success === false ?
                                                                            <div className="error_text">
                                                                                {resTask?.api_error && resTask?.api_error.message && <div>
                                                                                    <span>api_error: </span><span>{resTask?.api_error.message}</span>
                                                                                </div>}
                                                                                {resTask?.task_error && <div>
                                                                                    <span>task_error: </span><span>{resTask?.task_error}</span>
                                                                                </div>}
                                                                                {resTask?.exec_error && <div>
                                                                                    <span>exec_error: </span><span>{resTask?.exec_error}</span>
                                                                                </div>}
                                                                                {resTask?.output && <div>
                                                                                    <span>output: </span><span>{resTask?.output}</span>
                                                                                </div>}
                                                                            </div>
                                                                            :
                                                                            resCon && Array.isArray(resCon) && resCon.length > 1 ? resCon.map((item, index) => {
                                                                                return (
                                                                                    <img key={index} data-state="closer" loading="lazy" decoding="async" data-nimg="1" className="resImg cursor-pointer z-10 w-auto h-auto max-w-full max-h-full m-5" src={item} />
                                                                                )
                                                                            }) : (
                                                                                resCon
                                                                                    ?
                                                                                    <img data-state="closed" loading="lazy" decoding="async" data-nimg="1" className="resImg" src={resCon && resCon} />
                                                                                    :
                                                                                    <div></div>
                                                                            )
                                                                }
                                                            </div>
                                                            <div className="handles">
                                                                <Input value={prompt} onChange={e => setPrompt(e.target.value)} placeholder="Outer space, universe, movie lightingcinematic" />
                                                                <Button onClick={() => optSubmit(item)} >Generate</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : index === 1 ? (
                                                    <div className="">
                                                        <div className="top_generate">
                                                            <p className="modelName">{item.tit}</p>
                                                            <div className="playground" style={{ padding: '0 4.1vw' }}>
                                                                {
                                                                    loading ?
                                                                        (<div className="generating">
                                                                            <img src={LoadingGif} alt="" />
                                                                            {countDown > 0 ? <p>ETA: {`${remainingHours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`}</p> : <p>Accelerating generation...</p>}
                                                                        </div>)
                                                                        :
                                                                        !loading && resTask && resTask.is_success === false ?
                                                                            <div className="error_text">
                                                                                {resTask?.api_error && resTask?.api_error.message && <div>
                                                                                    <span>api_error: </span><span>{resTask?.api_error.message}</span>
                                                                                </div>}
                                                                                {resTask?.task_error && <div>
                                                                                    <span>task_error: </span><span>{resTask?.task_error}</span>
                                                                                </div>}
                                                                                {resTask?.exec_error && <div>
                                                                                    <span>exec_error: </span><span>{resTask?.exec_error}</span>
                                                                                </div>}
                                                                                {resTask?.output && <div>
                                                                                    <span>output: </span><span>{resTask?.output}</span>
                                                                                </div>}
                                                                            </div>
                                                                            :
                                                                            <div className="textToText">
                                                                                <div className="sender">Tell Me About 《a Farewell To Arms》</div>
                                                                                <div className="res_message">
                                                                                    s a youth of 18, ernest hemingway was eager to fight in the great war. poor vision kept him out of the army, so he joined the ambulance corps instead and was sent to france. then he transferred to italy where he became the first american wounded in that country during world war i. hemingway came out of the european battlefields with a medal for valor and a wealth of experience that he would, 10 years later, spin into literary gold with a farewell to arms. this is the story of lieutenant henry, an american, and catherine barkley, a british nurse. the two meet in italy, and almost immediately hemingway sets up the central tension of the novel: the tenuous nature of love in a time of war. during their first encounter, catherine tells henry about her fiancé of eight years who had been killed the year before in the somme. explaining why she hadn't married him, she says she was afraid marriage would be bad for him, then admits: i wanted to do something for him. you see, i didn't care about the other thing and he could have had it all. he could have had anything he wanted if i would have known. i would have married him or anything. i know all about it now. but then he wanted to go to war and i didn't know. the two begin an affair, with henry quite convinced that he "did not love catherine barkley nor had any idea of loving her. this was a game, like bridge, in which you said things instead of playing cards." soon enough, however, the game turns serious for both of them and ultimately henry ends up deserting to be with catherine.
                                                                                </div>
                                                                            </div>
                                                                }

                                                            </div>
                                                            <div className="handles">
                                                                <Input value={prompt} onChange={e => setPrompt(e.target.value)} placeholder="Outer space, universe, movie lightingcinematic" />
                                                                <Button onClick={() => optSubmit(item)} >Generate</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : index === 2 ? (
                                                    <div className="">
                                                        <div className="top_generate">
                                                            <p className="modelName">{item.tit}</p>
                                                            <div className="playground" style={{ backgroundImage: `url(${Musicgen})` }}>
                                                                {
                                                                    loading ?
                                                                        (<div className="generating">
                                                                            <img src={LoadingGif} alt="" />
                                                                            {countDown > 0 ? <p>ETA: {`${remainingHours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`}</p> : <p>Accelerating generation...</p>}
                                                                        </div>)
                                                                        :
                                                                        !loading && resTask && resTask.is_success === false ?
                                                                            <div className="error_text">
                                                                                {resTask?.api_error && resTask?.api_error.message && <div>
                                                                                    <span>api_error: </span><span>{resTask?.api_error.message}</span>
                                                                                </div>}
                                                                                {resTask?.task_error && <div>
                                                                                    <span>task_error: </span><span>{resTask?.task_error}</span>
                                                                                </div>}
                                                                                {resTask?.exec_error && <div>
                                                                                    <span>exec_error: </span><span>{resTask?.exec_error}</span>
                                                                                </div>}
                                                                                {resTask?.output && <div>
                                                                                    <span>output: </span><span>{resTask?.output}</span>
                                                                                </div>}
                                                                            </div>
                                                                            :
                                                                            <div className="textToAudio">
                                                                                <div className="playIcon" onClick={() => setPlay(!play)}>
                                                                                    {!play ? <img className="handleBtn" src={require('../assets/images/home/play-fill.png')} alt="" /> : <img className="handleBtn" src={require('../assets/images/home/pause.png')} alt="" />}
                                                                                </div>
                                                                                <div className="processCon">
                                                                                    <div className="times">00'00</div>
                                                                                    {!play ? <div className="process"></div> : <img className="processImg" src={require('../assets/images/home/sound.png')} alt="" />}
                                                                                    <div className="times">01'01</div>
                                                                                </div>

                                                                            </div>
                                                                }
                                                            </div>

                                                            <div className="handles">
                                                                <Input value={prompt} onChange={e => setPrompt(e.target.value)} placeholder="Outer space, universe, movie lightingcinematic" />
                                                                <Button onClick={() => optSubmit(item)} >Generate</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="">
                                                        <div className="top_generate">
                                                            <p className="modelName">{item.tit}</p>
                                                            <div className="playground" style={{ backgroundImage: `url(${StyleTTS_2})` }}>
                                                                {
                                                                    loading ?
                                                                        (<div className="generating">
                                                                            <img src={LoadingGif} alt="" />
                                                                            {countDown > 0 ? <p>ETA: {`${remainingHours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`}</p> : <p>Accelerating generation...</p>}
                                                                        </div>)
                                                                        :
                                                                        !loading && resTask && resTask.is_success === false ?
                                                                            <div className="error_text">
                                                                                {resTask?.api_error && resTask?.api_error.message && <div>
                                                                                    <span>api_error: </span><span>{resTask?.api_error.message}</span>
                                                                                </div>}
                                                                                {resTask?.task_error && <div>
                                                                                    <span>task_error: </span><span>{resTask?.task_error}</span>
                                                                                </div>}
                                                                                {resTask?.exec_error && <div>
                                                                                    <span>exec_error: </span><span>{resTask?.exec_error}</span>
                                                                                </div>}
                                                                                {resTask?.output && <div>
                                                                                    <span>output: </span><span>{resTask?.output}</span>
                                                                                </div>}
                                                                            </div>
                                                                            :
                                                                            <div className="textToAudio">
                                                                                <div className="playIcon" onClick={() => setPlay(!play)}>
                                                                                    {!play ? <img className="handleBtn" src={require('../assets/images/home/play-fill.png')} alt="" /> : <img className="handleBtn" src={require('../assets/images/home/pause.png')} alt="" />}
                                                                                </div>
                                                                                <div className="processCon">
                                                                                    <div className="times">00'00</div>
                                                                                    {!play ? <div className="process"></div> : <img className="processImg" src={require('../assets/images/home/sound.png')} alt="" />}
                                                                                    <div className="times">01'01</div>
                                                                                </div>
                                                                            </div>
                                                                }
                                                            </div>

                                                            <div className="handles">
                                                                <Input value={prompt} onChange={e => setPrompt(e.target.value)} placeholder="Outer space, universe, movie lightingcinematic" />
                                                                <Button onClick={() => optSubmit(item)} >Generate</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                })
                            }
                        </Slider>
                    </div>

                    <Link to="/playground" className={`moreModels`}>
                        <span>{`Explore more models>>`}</span>
                    </Link>

                </section>

                <section className="third_page">
                    <div className="left"></div>
                    <div className="right">
                        <div className="top_tit">
                            <span className="white_text">WHY</span>
                            <span className="white_text">CHOOSE TO USE AIGIC</span>
                        </div>
                        <div className="right_item">
                            <span className="tit">Always Use AI Open-source Models</span>
                            <span className="right_item_content">You don't need expensive GPUs and hardware, you can run over 1000 open-source models on AIGIC at any time and copy the code into your own project.</span>
                        </div>

                        <div className="right_item item2">
                            <span className="tit">The Lowest Usage Price.</span>
                            <span className="right_item_content">AIGIC utilizes the most advanced aggregated AI Computility network, allowing your application to run at the lowest cost and achieve the highest returns.</span>
                        </div>

                        <div className="right_item item3">
                            <span className="tit">Full Encryption To Protect Privacy</span>
                            <span className="right_item_content">AIGIC uses advanced modeling and data transmission encryption technology to protect user model data and copyright, as well as user privacy and trade secrets.</span>
                        </div>

                        <div className="right_item item4">
                            <span className="tit">Deploy Custom AI Models.</span>
                            <span className="right_item_content">In addition to the models in the existing model library, you can also deploy your own custom models, which are stored on cloud servers and you only need to pay fees when using them.</span>
                        </div>
                    </div>
                </section>

                <section className="fifth_page">
                    <div className="fifth_page_tit">
                        <p>PAY ONLY</p>
                        <p>WHEN RUNNING CODE</p>
                    </div>
                    <div className="fifth_page_con">
                        <img src={require('../assets/images/home/adminPage.png')} alt="" />
                        <div className="adminHomeImg"></div>
                        <span>There is no need to pay any fees for the model and hardware, AIGIC will only calculate traffic and charge when the code is copied into your application project and runs. During use, AIGIC will provide you with a complete traffic monitoring system to help you keep track of your account usage at all times.</span>
                        <span>At the same time, AIGIC will give a certain amount of computing power to registered users every day, which allows you to complete the testing phase of a small number of users with almost no cost until the project is officially launched and has a large number of users.</span>
                        <Link to={'/info'}><button>Get Free Computility</button></Link>

                    </div>
                </section>
            </div>

            <section className="sixth_page">
                <div className="sixth_page_tit">
                    <p>AIGIC is working with AI</p>
                    <p>developers</p>
                    <p>Realize countless</p>
                    <p>imaginations</p>
                </div>
                <div className="sixth_page_con">
                    <div className="middle_info">
                        <Slider {...settings}>
                            <div className="ava_message" style={{ width: '67.44vw' }}>
                                <div className="ava_message_top">
                                    <img src={require('../assets/images/home/sixth_ava.png')} alt="" />
                                    <div className="ava_message_top_right">
                                        <p>Rosario</p>
                                        <p>Music software developer</p>
                                    </div>
                                </div>
                                <div className="bottom_content">
                                    <span>Now, I can create a plugin in DAW, just write the melody, and AI can help me complete the harmony 🎶 and beat 🥁.</span>
                                </div>
                            </div>
                            <div className="ava_message" style={{ width: '67.44vw' }}>
                                <div className="ava_message_top">
                                    <img src={require('../assets/images/home/sixth_ava2.png')} alt="" />
                                    <div className="ava_message_top_right">
                                        <p>Liam Heath</p>
                                        <p>Freelancing</p>
                                    </div>
                                </div>
                                <div className="bottom_content">
                                    <span>I don't need a GPU, I can work anytime and anywhere with my laptop, and I can find any model I need on AIGIC！！</span>
                                </div>
                            </div>
                            <div className="ava_message" style={{ width: '67.44vw' }}>
                                <div className="ava_message_top">
                                    <img src={require('../assets/images/home/sixth_ava3.png')} alt="" />
                                    <div className="ava_message_top_right">
                                        <p>Tina LEEEEEEEEE</p>
                                        <p>Vlogger</p>
                                    </div>
                                </div>
                                <div className="bottom_content">
                                    <span>Now I can dub my videos in various tones 🎙️🎙️, and it's almost free of charge!🎉</span>
                                </div>
                            </div>
                        </Slider>


                    </div>

                    {/* <div className="sixth_p_bottom">
                        <img src={require('../assets/images/home/sixth_btmIcon.png')} alt="" />
                        <img src={require('../assets/images/home/sixth_btmIcon2.png')} alt="" />
                        <img src={require('../assets/images/home/sixth_btmIcon3.png')} alt="" />
                    </div> */}
                </div>
            </section>

            <footer className="flex flex-row items-center justify-between">
                <Link className="footer_logo" to={'/'}><img src={require('../assets/images/home/footer_logo.png')} alt="" /></Link>
                <p>{`AIGIC ©️ 2024`}</p>

            </footer>

        </div>
    );
};

export default Home;