import { useLocation } from 'react-router-dom';
import './styles/header_dark.scss'
import { useState } from "react";


const Header = () => {
    const location = useLocation();
    const pathname = location.pathname;
    const host = window.location.host;
    const hostname = window.location.hostname;
    const targetDocsHost = hostname !== 'localhost' ? 'https://docs.' + host.replace('www.', '') : 'http://' + host

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };


    return (
        <header style={isExpanded || pathname !== '/' ? { backgroundColor: '#22075E' } : {}}>
            <nav className='flex flex-row items-center justify-between'>
                <a href="/">
                    <div className="logo">
                        <img src={require('../assets/images/logo_.png')} alt="" />
                    </div>
                </a>


                <div className="menu">
                    <img className='menuIcon' src={require(`../assets/images/headers/${isExpanded ? 'close' : 'menu'}.png`)} onClick={toggleExpand} alt="" />
                    <ul className={isExpanded ? 'expanded' : 'collapsed'}>
                        <li>
                            <a href="/playground" onClick={toggleExpand}>
                                <span>Built with AIGIC</span>
                                <img src={require('../assets/images/headers/right.png')} alt="" />
                            </a>
                        </li>

                        <li>
                            <a href={targetDocsHost} target='_blank' onClick={toggleExpand}>
                                <span>Docs</span>
                                <img src={require('../assets/images/headers/right.png')} alt="" />
                            </a>
                        </li>
                    </ul>
                </div>

            </nav>
        </header>
    );
};

export default Header;