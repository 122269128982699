// routes.js

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Playground from './pages/Playground_list';
import Home from './pages/Home';
import Info from './pages/Info';
import ReceiveMessage from './pages/ReceiveMessage';

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<Home />}
      />
      <Route
        path="/playground"
        element={<Playground />}
      />
      <Route
        path="/info"
        element={<Info />}
      />
      <Route
        path="/receive-message"
        element={<ReceiveMessage />}
      />

    </Routes>
  );
};

export default AppRoutes;
